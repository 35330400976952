import axios from 'axios';
import router from "@/router";

const state = {
    user: null,
    token: null,
};

const getters = {

    getUser: state => state.user,

    userToken: state => state.token
};

const actions = {

    checkAuth({rootGetters}, authorized){
        const user = rootGetters.getUser
        if(!user ){
            router.push('/logout')
            return false
        }else{
            if(authorized){
                for(let index in authorized){
                    if(user.department.department_code == authorized[index]){
                        return true
                    }
                }
                return false
            }
            return false
        }
    },
    
    async Register({}, form) {
        const response = await axios.post('register', form)
        return response
    },

    async ConfirmOTP({}, form) {
        const response = await axios.post('confirm-otp', form)
        return response
    },

    async changePIN({}, form) {
        const response = await axios.post('change-pin', form)
        return response
    },

    async Login({commit}, form) {
        const response = await axios.post('login', {}, {
            auth: {
                username:form.email,
                password:form.password
            }
        })
        
        await commit('setUser', response.data.user)
        return response
    },

    async updateProfile({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('profile-update',form, headers)
        return response
    },

    async getProfile({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }


        const response = await axios.get('user-detail',{ params: {id: form.id}, headers: headers})
        return response
    },

    setLogin({commit}, data){
        commit('setuserdata',data)
    },

    async Logout({commit}){
        commit('logout')
        return true;
    },

    setUserPhone({}, phone){
        state.phone = phone
    }
};

const mutations = {
    setUser(state, username){
        state.user = username
    },
    setPosts(state, posts){
        state.posts = posts
    },
    logout(state){
        state.user      = null
        state.worker   = null
        state.token     = null
    },
    setuserdata(state,data){
        console.log(data,"USERRDATA")
        state.user      = data.user
        state.token     = data.token
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};
