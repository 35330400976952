import axios from 'axios';

const state = {
    list: null
};

const getters = {};

const actions = {

    async getBanners({rootGetters}, form) {
        return await axios.get('banner/list', {params: form})
    },

    async createBanner({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        return await axios.post('banner/create', form, headers)
    },

    async updateBanner({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        return await axios.post('banner/update', form, headers)
    },

    async detailBanner({rootGetters}, form) {
        return await axios.get('banner/detail', {params: form})
    },

    async deleteBanner({rootGetters}, form) {
        return await axios.post('banner/delete', form)
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
