<template>
  <RouterView></RouterView>
  <div id="app"></div>
</template>

<script>
import default_avatar_kails from '@/assets/img/kailsIcon.png'
import default_avatar_zeals from '@/assets/img/avatar_default.svg'

export default {
  data() {
    return {
      documentTitle: ''
    }
  },
  methods: {
    setDocumentTitle() {
      if (process.env.VUE_APP_FLAVOR === "ZEALS") {
        this.documentTitle = "Dashboard Zeals Asia - Affiliate Platform";
      } else {
        this.documentTitle = "Dashboard KAI Lifestyle";
      }
    },
    setFavicon() {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (process.env.VUE_APP_FLAVOR === "ZEALS") {
        faviconLink.href = default_avatar_zeals
      } else if(process.env.VUE_APP_FLAVOR === "KAILS") {
        faviconLink.href = default_avatar_kails;
      }
    }
  },
  mounted() {
    this.setDocumentTitle();
    document.title = this.documentTitle;
    this.setFavicon();
  }
}
</script>

<style>
</style>
