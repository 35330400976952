import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchBrand({ rootGetters}, form) {
        return await axios.get('campaign/partner-search', { params: form})
    },


    async getBrands({ rootGetters}, form) {
        return await axios.get('partner/list',{ params: form})
    },

    async createBrand({ rootGetters}, form) {
        return await axios.post('partner/create',form)
    },
    
    async updateBrand({ rootGetters}, form) {
        return await axios.post('partner/update',form)
    },
    
    async detailBrand({ rootGetters}, form) {
        return await axios.get('partner/detail',{ params: form})
    },
    
    async deleteBrand({ rootGetters}, form) {
        return await axios.post('partner/delete',form)
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
