import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    // async searchCategories({rootGetters}, form) {
    async searchIndustries({rootGetters}, form) {
        return await axios.get('campaign/category-search', { params: form})
    },

    async getIndustries({rootGetters}, form) {
        return await axios.get('category/list',{ params: form})
    },

    async createIndustry({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        return await axios.post('category/create',form, headers)
    },
    
    async updateIndustry({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        return await axios.post('category/update',form, headers)
    },
    
    async detailIndustry({rootGetters}, form) {
        return await axios.get('category/detail',{ params: form})
    },
    
    async deleteIndustry({rootGetters}, form) {
        return await axios.post('category/delete',form)
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
