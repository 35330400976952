import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async createInvoice({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        const response = await axios.post('invoice-create',form, headers)
        return response
    },

    async removeInvoice({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
    
        const response = await axios.delete('invoice-remove',{ params: form, headers: headers})
        return response
    },
    
    async invoicePaid({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }
    
        return await axios.post('invoice-paid',form, headers)
    },

    async uploadReceipt({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        return await axios.post('invoice/upload',form, headers)
    },

    async invoice({rootGetters}, form) {
        return await axios.get('invoice/get', { params: form })
    },

    async invoiceList({rootGetters}, form) {
        return await axios.get('invoice/list', { params: form })
    }
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
