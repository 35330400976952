import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchCategory({ rootGetters}, form) {
        return await axios.get('campaign/category-search', { params: form})
    },

    async getCategories({ rootGetters}, form) {
        return await axios.get('category-list',{ params: form})
    },

    async createCategory({ rootGetters}, form) {
        return await axios.post('category-create',form)
    },
    
    async updateCategory({ rootGetters}, form) {
        return await axios.put('category-update',form)
    },
    
    async detailCategory({ rootGetters}, form) {
        return await axios.get('category-detail',{ params: form})
    },
    
    async deleteCategory({ rootGetters}, form) {
        const response = await axios.delete('category-delete',{ params: form})
        return response
    },

    async activateCategory({ rootGetters}, form) {
        return await axios.post('category-activate',form)
    },

    async deactivateCategory({ rootGetters}, form) {
        return await axios.post('category-deactivate',form)
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
