import axios from 'axios';

const state = {
    list: null
};

const getters = {};

const actions = {

    async getAffiliateGroups({rootGetters}, form) {
        return await axios.get('affiliate-group/list', {params: form})
    },

    async createAffiliateGroup({rootGetters}, form) {
        return await axios.post('affiliate-group/create', form)
    },

    async updateAffiliateGroup({rootGetters}, form) {
        return await axios.post('affiliate-group/update', form)
    },

    async detailAffiliateGroup({rootGetters}, form) {
        return await axios.get('affiliate-group/detail', {params: form})
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
