import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import axios from 'axios';

import VueCookies from 'vue-cookies'
import BootstrapVue3 from "bootstrap-vue-3";
import RollbarPlugin from '../rollbar'; // Path to your rollbar.js file

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import VueGoogleMaps from '@fawmi/vue-google-maps'
import './assets/scss/style.scss'

import './registerServiceWorker'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowLeft, 
  faClock, 
  faClockRotateLeft,
  faCopy,
  faEllipsis, 
  faPowerOff, 
  faQrcode, 
  faUser, 
  faAngleRight, 
  faCheck, 
  faCheckCircle, 
  faSearch, 
  faPlus, faScissors, faBroom, faFire, faRecordVinyl, faChartLine, faMinimize, faMaximize, 
  faCog, 
  faXmark, 
  faImage, 
  faCircle, 
  faCircleCheck, 
  faEllipsisVertical, 
  faAngleLeft,
  faFileLines,
  faDatabase,
  faBedPulse,
  faFile,
  faBox,
  faBoxes,
  faShop,
  faCertificate,
  faShoppingBag,
  faUserDoctor,
  faUserNurse,
  faHospital,
  faBuilding,
  faStethoscope,
  faCalendar,
  faRocket,
  faCalendarAlt,
  faKitMedical,
  faSyringe,
  faFileInvoice,
  faChartBar,
  faChartPie,
  faCloudBolt,
  faUsers,
  faCloudUpload,
  faPeopleGroup,
  faChild,
  faChalkboardTeacher,
  faClipboardList,
  faBook,
  faImages,
  faComments,
  faMoneyBill,
  faBank,
  faSliders,
  faEnvelope,
  faBrain,
  faTerminal,
  faUserCircle,
  faBell,
  faGlobe,
  faLink,
  faBolt,
  faCogs,
  faEye,
  faPencilAlt,
  faTrash,
  faWallet,
  faUserShield,
  faFileExcel,
  faCrown,
  faEdit,
  faMinus,
  faStop,
  faShoppingCart,
  faPlay,
  faDownload,
  faListDots,
  faTable
} from '@fortawesome/free-solid-svg-icons'

library.add(faArrowLeft,
  faClock, 
  faClockRotateLeft,
  faCopy,
  faEllipsis, 
  faQrcode, 
  faUser, 
  faPowerOff, 
  faAngleRight, 
  faAngleLeft,
  faCheckCircle, 
  faCheck,
  faFileInvoice,
  faSearch, 
  faPlus, 
  faScissors, 
  faBroom, 
  faFire,
  faRecordVinyl, 
  faChartLine,
  faMinimize,
  faMaximize, 
  faCog, 
  faXmark, 
  faImage, 
  faCircle, 
  faCircleCheck, 
  faEllipsisVertical,
  faFileLines,
  faDatabase,
  faBedPulse,
  faFile,
  faBox,
  faBoxes,
  faShop,
  faCertificate,
  faShoppingBag,
  faUserDoctor,
  faUserNurse,
  faHospital,
  faBuilding,
  faUser,
  faStethoscope,
  faCalendar,
  faRocket,
  faCalendarAlt,
  faKitMedical,
  faSyringe,
  faChartBar,
  faChartPie,
  faCloudBolt,
  faUsers,
  faPeopleGroup,
  faChild,
  faChalkboardTeacher,
  faClipboardList,
  faBook,
  faImages,
  faComments,
  faCloudUpload,
  faMoneyBill,
  faBank,
  faSliders,
  faEnvelope,
  faBrain,
  faTerminal,
  faPowerOff,
  faUserCircle,
  faBell,
  faGlobe,
  faLink,
  faBolt,
  faFile,
  faCogs,
  faEye,
  faPencilAlt,
  faTrash,
  faCheckCircle,
  faWallet,
  faUserShield,
  faBroom,
  faFileExcel,
  faCrown,
  faEdit,
  faMinus,
  faStop,
  faMoneyBill,
  faFileExcel,
  faShop,
  faCalendar,
  faLink,
  faShoppingCart,
  faPlay,
  faImages,
  faMoneyBill,
  faFire,
  faCertificate,
  faDownload,
  faGlobe,
  faListDots,
  faTable
  )

if(process.env.VUE_APP_FLAVOR === "ZEALS"){
  axios.defaults.baseURL = process.env.VUE_APP_API_ZEALS_URL;
}else if(process.env.VUE_APP_FLAVOR === "KAILS"){
  axios.defaults.baseURL = process.env.VUE_APP_API_KAILS_URL;
}

//Configure header auth
axios.interceptors.request.use(config => {
  const token = store.getters['userToken']; // Get the token from Vuex store
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

createApp(App).component('font-awesome-icon', FontAwesomeIcon)
  .use(store)
  // .use(RollbarPlugin)
  .use(BootstrapVue3)
  .use(router)
  .use(VueCookies)
  .use(store)
  .use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCHBtx96V3XiITY2KKoJVxTKe1xsm5Ifhk',
    },
  })
  .mount('#app')