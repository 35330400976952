import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async getMicrositeRecords({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('microsite-records',{ params: form, headers: headers})
        return response
    },
    
    async getMicrosites({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('microsites/list',{ params: form, headers: headers})
        return response
    },
    
    async createMicrosite({rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsites/create',form, headers)
        return response
    },
    
    async updateMicrosite({rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsites/update',form, headers)
        return response
    },
    
    async detailMicrosite({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.get('microsites/detail',{ params: form, headers: headers})
        return response
    },

    async deleteMicrosite({ rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsites/delete',form, headers)
        return response
    },

    async activateMicrosite({ rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsites/activate',form, headers)
        return response
    },

    async deactivateMicrosite({ rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsites/deactivate',form, headers)
        return response
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
