import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async getDashboardSummary({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        let response = []
        response['growth']  = await axios.get('summary-growth',{ params: form, headers: headers})
        response['gender']  = await axios.get('summary-gender',{ params: form, headers: headers})
        response['counter'] = await axios.get('summary-counter',{ params: form, headers: headers})
        response['age']     = await axios.get('summary-age',{ params: form, headers: headers})
        response['cities']  = await axios.get('summary-cities',{ params: form, headers: headers})
        response['campaign']   = await axios.get('summary-campaign',{ params: form, headers: headers})
        response['campaign_board']   = await axios.get('summary-campaign-board',{ params: form, headers: headers})

        return response
    },

    async getCampaignInsight({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response  = await axios.get('campaign/report',{ params: form, headers: headers})

        return response
    },

    async getReportRange({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response  = await axios.get('campaign/report-range',{ params: form, headers: headers})

        return response
    },
    
    async getVoucherReport({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response  = await axios.get('campaign/report-voucher',{ params: form, headers: headers})

        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
