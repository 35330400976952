import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchReward({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('reward-search', { params: form, headers: headers})
        return response
    },

    async getRewards({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('reward-list',{ params: form, headers: headers})
        return response
    },

    async createReward({rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('reward-create',form, headers)
        return response
    },
    
    async updateReward({rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.put('reward-update',form, headers)
        return response
    },
    
    async detailReward({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.get('reward-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteReward({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.delete('reward-delete',{ params: form, headers: headers})
        return response
    },

    async activateReward({rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('reward-activate',form, headers)
        return response
    },

    async deactivateReward({rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('reward-deactivate',form, headers)
        return response
    },


};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
