import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchWilayah({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('wilayah/search', { params: form, headers: headers})
        return response
    },


    async getWilayah({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }


        const response = await axios.get('wilayah-list',{ params: form, headers: headers})
        return response
    },

    async createWilayah({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('wilayah-create',form, headers)
        return response
    },
    
    async updateWilayah({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('wilayah-update',form, headers)
        return response
    },
    
    async detailWilayah({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.get('wilayah-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteWilayah({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.delete('wilayah-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
